<template lang="pug">
#BannerSection
  .columns.is-vcentered
    .column.is-5.bg-main-color.pl-6
      h1.is-size-3.p-4
        strong Garantizamos el total anonimato del denunciante, tanto si es empleado, proveedor o cliente
</template>
<script>
export default {
  name: 'BannerSection',
};
</script>
<style scoped>
#BannerSection {
  background-image: url('../assets/megafonos.png');
  height: 730px;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  align-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
}
</style>
