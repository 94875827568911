import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import VueScrollTo from 'vue-scrollto';

import Buefy from 'buefy';
import 'buefy/dist/buefy.css';
import './assets/styles.css';

import { VueReCaptcha } from 'vue-recaptcha-v3';

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY });

Vue.use(VueScrollTo, {
  offset: -100,
});

Vue.use(Buefy);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
