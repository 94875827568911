<template lang="pug">
#BenefitsSection  
  .container.p-5
    h3.is-size-3
      strong.text-main-color Beneficios
    br
    .columns
      .column.has-text-centered 
        strong.is-size-4.text-main-color Prevención
        p.mt-3.fz-18 Contar con una línea de denuncia le permite a la empresa anticiparse y poder solucionar proactivamente eventuales casos que no cumplen con las definiciones de cumplimiento y ética de las organizaciones.
      .column.has-text-centered
        strong.is-size-4.text-main-color Confidencialidad
        p.mt-3.fz-18 Nuestro canal de denuncia garantiza el total anonimato del denunciante, tanto si es empleado, proveedor o cliente. 
      .column.has-text-centered
        strong.is-size-4.text-main-color Múltiples Denuncias
        p.mt-3.fz-18 Un canal de denuncia permite no solo, revelar delitos financieros, sino que también se utiliza para informar sobre casos de violencia, acoso, mobbing, robos internos y más.
      .column.has-text-centered
        strong.is-size-4.text-main-color Ahorro en Costos
        p.mt-3.fz-18 A través del uso del canal de denuncias las organizaciones se pueden anticipar a eventuales situaciones que puedan significar altos costos o multas de los reguladores.
    br
</template>
<script>
export default {
  name: 'BenefitsSection',
};
</script>
