<template lang="pug">
#FeaturesSection.gradiend-bg
  .container.p-5
    h3.is-size-3
      strong Funcionalidades
    
    .columns.mt-5.mb-5
      .column.has-text-centered
        img(src="@/assets/images/icon8.png" style="height:70px")
        p.fz-18 Formulario configurable con elementos precargados y espacio de preguntas abiertas.
      .column.has-text-centered
        img(src="@/assets/images/icon1.png" style="height:70px")
        p.fz-18 El cliente define quién es el receptor de las denuncias por su parte. Se puede definir un receptor secundario en caso de que exista un conflicto de interés.
      .column.has-text-centered
        img(src="@/assets/images/icon2.png" style="height:70px")
        p.fz-18 El denunciante puede adjuntar archivos al formulario de denuncia.
      .column.has-text-centered
        img(src="@/assets/images/icon3.png" style="height:70px")
        p.fz-18 El denunciante puede elegir recibir notificaciones sobre el estado de la denuncia.
    //- h4.is-size-4 Gestión del Caso en Sistema Ceptinel:
    
    .columns.mt-5.mb-5
      .column.has-text-centered
        img(src="@/assets/images/icon4.png" style="height:70px")
        p.fz-18  Se crea caso en el sistema automáticamente con la denuncia.
      .column.has-text-centered
        img(src="@/assets/images/icon5.png" style="height:70px")
        p.fz-18 El usuario puede actualizar el estado del caso.
      .column.has-text-centered
        img(src="@/assets/images/icon6.png" style="height:70px")
        p.fz-18 El denunciante puede consultar el estado del caso.
      .column.has-text-centered
        img(src="@/assets/images/icon7.png" style="height:70px")
        p.fz-18 Crear perfiles por usuario.
    
</template>
<script>
export default {
  name: 'FeaturesSection',
};
</script>
