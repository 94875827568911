<template lang="pug">
#ContactSection.gradiend-bg
  form.container.p-5(@submit="submitForm")
    h3.is-size-3
      strong ¡Conversemos!

    .columns.mt-5.mb-5
      .column
        section.columns
          .column
            b-field(label="Nombre")
              b-input(v-model="form.name")
          .column
            b-field(label="Apellido")
              b-input(v-model="form.surname")

        section.columns
          .column
            b-field(label="Empresa")
              b-input(v-model="form.company")

        section.columns
          .column
            b-field(label="Email")
              b-input(v-model="form.email", type="email")

        section.columns
          .column
            b-field(label="Telefono")
              b-input(v-model="form.phone", type="tel")
      .column
        section.columns
          .column
            b-field(label="Comentario | Consulta")
              b-input(v-model="form.message", type="textarea")

        section.columns
          .column
            b-button(type="is-primary", native-type="submit" :disabled="isLoading" :loading="isLoading") Enviar
</template>
<script>
import axios from 'axios';
export default {
  name: 'ContactSection',
  data() {
    return {
      isLoading: false,
      form: {
        email: '',
        name: '',
        surname: '',
        company: '',
        phone: '',
        message: '',
      },
    };
  },
  methods: {
    async submitForm(e) {
      e.preventDefault();

      await this.$recaptchaLoaded();
      const token = await this.$recaptcha('login');
      if (!token) return;

      if (
        this.form.name == '' ||
        this.form.surname == '' ||
        this.form.company == '' ||
        this.form.email == '' ||
        this.form.phone == '' ||
        this.form.message == ''
      ) {
        this.$buefy.toast.open({
          duration: 5000,
          message: `Tienes datos incompletos`,
          position: 'is-bottom',
          type: 'is-danger',
        });
      } else {
        this.isLoading = true;
        const apiUrl = `${window.location.origin}/api/email-dispatcher`;

        axios
          .post(apiUrl, {
            name: this.form.name,
            surname: this.form.surname,
            company: this.form.company,
            email: this.form.email,
            phone: this.form.phone,
            message: this.form.message,
            token,
          })
          .then(
            () => {
              this.form.name = '';
              this.form.surname = '';
              this.form.company = '';
              this.form.email = '';
              this.form.phone = '';
              this.form.message = '';
              this.$buefy.toast.open({
                duration: 5000,
                message: `Mensaje enviado`,
                position: 'is-bottom',
                type: 'is-success',
              });
              this.isLoading = false;
            },
            (error) => {
              this.$buefy.toast.open({
                duration: 5000,
                message: `Se ha producido un error, intente de nuevo`,
                position: 'is-bottom',
                type: 'is-danger',
              });
              this.isLoading = false;
              console.warn(error);
              throw new Error(`Error sending email ${error}`);
            }
          );
      }
    },
  },
};
</script>
