<template lang="pug">
#AboutSection.container
  .columns.p-5.is-vcentered
    .column.is-8
      h1.is-size-1
        .inline-items
          .text-main-color Quiénes
            | &nbsp;
            strong somos
          img(src="@/assets/az.png", style="height: 40px")
          img(src="@/assets/cep_logotipo_full.png", style="height: 40px")
      br
      p 
        strong az | albagli zaliasnik
        |, firma de abogados líder en el área de Cumplimiento, y 
        strong Ceptinel
        |, líder en el sector RegTech, han desarrollado un canal de denuncias externo con el fin de entregar una solución tecnológica y eficiente para sus clientes.
      br
      h3
        strong.text-main-color Portal Ético

      ul(style="list-style: inside")
        li.fz-.mt-5
          | Somos un Canal de Denuncias automatizado, que proporciona alertas en casos de denuncias de colaboradores, proveedores, y clientes.
        li.fz-.mt-5
          | El usuario define el formulario de denunciante en función de opciones predefinidas.
        li.fz-.mt-5
          | Una vez que se registra una denuncia, se envía una alerta al administrador, quien inmediatamente puede comenzar el procedimiento de gestión del caso.
        li.fz-.mt-5
          | El administrador puede rastrear sus casos en el panel principal.
      br
    .column.image-manos-bg
</template>
<script>
export default {
  name: "AboutSection",
};
</script>
<style>
.image-manos-bg {
  background-image: url("../assets/foto-web-manos.png");
  height: 430px;
  background-size: cover;
  background-attachment: scroll;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  align-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
}
</style>
