<template lang="pug">
b-navbar#TheNavbar(:transparent="true", fixed-top)
  template(#brand="")
    b-navbar-item(tag="router-link", :to="{ path: '/' }")
    img(
      src="@/assets/logo.png",
      style="height: 120px; padding: 1rem",
      alt="portaletico"
    )
  template(#end="")
    b-navbar-item.fz-22(@click="scrollTo('AboutSection')")
      | Quiénes somos
    b-navbar-item.fz-22(@click="scrollTo('FeaturesSection')")
      | Funcionalidades
    b-navbar-item.fz-22(@click="scrollTo('BenefitsSection')")
      | Beneficios
    b-navbar-item.fz-22(@click="scrollTo('ContactSection')")
      | Solicita tu DEMO
    b-navbar-item.fz-22(@click="scrollTo('TheFooter')")
      | Contacto
</template>
<script>
export default {
  name: "TheNavbar",
  methods: {
    scrollTo(route) {
      this.$scrollTo("#" + route);
    },
  },
};
</script>
