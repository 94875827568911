<template lang="pug">
#HomeView 
  TheNavbar
  BannerSection
  AboutSection
  FeaturesSection
  BenefitsSection
  ContactSection
  TheFooter
</template>
<script>
import TheNavbar from '../components/TheNavbar.vue';
import BannerSection from '../components/BannerSection.vue';
import AboutSection from '../components/AboutSection.vue';
import FeaturesSection from '../components/FeaturesSection.vue';
import BenefitsSection from '../components/BenefitsSection.vue';
import ContactSection from '../components/ContactSection.vue';
import TheFooter from '../components/TheFooter.vue';
export default {
  components: {
    TheNavbar,
    AboutSection,
    BannerSection,
    FeaturesSection,
    BenefitsSection,
    ContactSection,
    TheFooter,
  },
  name: 'HomeView',
};
</script>
